import React from "react";

function Paymentfailure() {
  return (
    <div>
      <div>Paymentfailure Page</div>
    </div>
  );
}

export default Paymentfailure;
