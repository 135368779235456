import React from 'react';
import { useParams } from 'react-router-dom';

function PaintingChargesPage() {
  // Extract the cityName from the URL
  const { cityName } = useParams();

  return (
    <div>
      <h1>Painting Charges in {cityName}</h1>
      {/* Add your content specific to the city */}
    </div>
  );
}

export default PaintingChargesPage;