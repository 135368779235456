import React, { useState } from 'react';
import moment from 'moment';


const BlogContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    pincode: '',
    interestedIn: ''
  });

  const [submissionStatus, setSubmissionStatus] = useState({
    loading: false,
    success: false,
    error: null
  });

  const [additionalData, setAdditionalData] = useState({
    userId: null,
    utm_source: null,
    storedQuery: null,
    selectedAddress: null,
    sdata: null
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Reset previous submission status
    setSubmissionStatus({ loading: true, success: false, error: null });

    const config = {
      url: "https://api.vijayhomeservicebengaluru.in/api/addnewenquiry",
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: {
        customerData: formData,
        date: moment().format("MM-DD-YYYY"),
        userId: additionalData.userId || null,
        mobile: formData.phone,
        name: formData.name,
        Time: moment().format("h:mm:ss a"),
        address: additionalData.selectedAddress?.address || null,
        deliveryAddress: additionalData.selectedAddress || null,
        category: additionalData.sdata?.category || null,
        reference1: "blogform",
        reference2: additionalData.utm_source || additionalData.storedQuery || null,
        city: null, // You might want to add city detection or input
        comment: additionalData.sdata?.pName || null,
        intrestedfor: formData.interestedIn,
      },
    };

    try {
      const response = await fetch(config.url, {
        method: config.method,
        headers: config.headers,
        body: JSON.stringify(config.data)
      });
      if (!response.ok) {
        throw new Error('Submission failed. Please try again.');
      }

      // Clear form after successful submission
      setFormData({
        name: '',
        email: '',
        phone: '',
        pincode: '',
        interestedIn: ''
      });

      // Set success status
      setSubmissionStatus({ 
        loading: false, 
        success: true, 
        error: null 
      });

      // Optional: Clear success message after 3 seconds
      setTimeout(() => {
        setSubmissionStatus({ 
          loading: false, 
          success: false, 
          error: null 
        });
      }, 3000);

    } catch (error) {
      // Handle any network or submission errors
      setSubmissionStatus({ 
        loading: false, 
        success: false, 
        error: error.message 
      });
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit} className="d-flex flex-wrap gap-4" style={{marginTop: "20px",}}>
        <div className="flex-1">
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div className="flex-1">
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div className="flex-1">
          <input
            type="tel"
            name="phone"
            placeholder="Phone"
            value={formData.phone}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            pattern="[0-9]{10}"
            required
          />
        </div>
        <div className="flex-1">
          <input
            type="text"
            name="pincode"
            placeholder="Pincode"
            value={formData.pincode}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            pattern="[0-9]{6}"
            required
          />
        </div>
        <div className="flex-1">
          <select
            name="interestedIn"
            value={formData.interestedIn}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          >
            <option value="">Interested In</option>
            <option value="painting">Painting</option>
            <option value="cleaning">Cleaning</option>
            <option value="pest control">Pest Control</option>
            <option value="appliance repair">Appliance Repair</option>
          </select>
        </div>
        <div className="flex-1">
        <button
              type="submit"
              disabled={submissionStatus.loading}
              className={`w-full text-white py-2 px-4 rounded-md transition-colors ${
                submissionStatus.loading 
                  ? 'bg-gray-400 cursor-not-allowed' 
                  : 'bg-green-500 hover:bg-green-600'
              }`}
            >
              {submissionStatus.loading ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </form>
        

     {submissionStatus.success && (
    <div className="mt-4 p-4 bg-green-100 text-green-800 rounded-md flex items-center">
       <span className="mr-2 text-green-600">✓</span>
          Thank you! Your enquiry has been submitted successfully.
        </div>
  )}

  {submissionStatus.error && (
    <div className="mt-4 p-4 bg-red-100 text-red-800 rounded-md flex items-center">
       <span className="mr-2 text-red-600">!</span>
          {submissionStatus.error}
        </div>
  )}
        
       
    </div>
  );
};

export default BlogContactForm;